<template>
  <div class="vBlockWiFiInformation">
    <VAdminDisplayingWiFi
      :visible-wi-fi="visibleWiFi"
      @toggleVisibleWiFi="toggleVisibleWiFi"
    />
    <div class="vBlockWiFiInformation__container">
      <div class="vBlockWiFiInformation__info">
        <div class="vBlockWiFiInformation__info-title">
          Информацию о Wi-Fi
        </div>
        <div class="vBlockWiFiInformation__info-text">
          Если в вашем заведении есть Wi-Fi для гостей, то вы можете разместить
          информацию о наименовании и пароле от сети Wi-Fi.
        </div>
        <div class="vBlockWiFiInformation__info-text">
          Гости смогут увидеть эту информацию на странице заказа, нажав на
          кнопку Wi-Fi.
        </div>
      </div>
      <div class="vBlockWiFiInformation__header">
        Настройки сети
      </div>
      <fieldset class="section-profile__group">
        <!-- Наименование сети Wi-Fi -->
        <vInput
          id="network"
          v-model="network"
          :value="network"
          placeholder="Наименование сети Wi-Fi"
          :error="errorNetwork"
          :disabled="!visibleWiFi"
        />
        <!-- Пароль от сети Wi-Fi -->
        <vInput
          id="password"
          v-model="password"
          :value="password"
          placeholder="Пароль от сети Wi-Fi"
          :error="errorPassword"
          :disabled="!visibleWiFi"
        />
      </fieldset>
      <vButton
        type="submit"
        :disabled="disableButton"
        @click="onInformationWiFi"
      >
        Сохранить
      </vButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VAdminDisplayingWiFi from '@/components/adminMenu/v-admin-displaying-wi-fi'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
export default {
  name: 'VBlockWiFiInformation',
  components: {
    VAdminDisplayingWiFi,
    vInput,
    vButton,
  },
  data() {
    return {
      network: '',
      password: '',
      visibleWiFi: null,
      disableButton: true,
      errorNetwork: '',
      errorPassword: '',
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },
  watch: {
    network() {
      if (!this.network.length) {
        this.errorNetwork = 'Заполните наименование сети Wi-Fi'
      } else {
        this.errorNetwork = ''
      }
      this.disableButton = false
    },
    password() {
      if (this.password.length < 8 && this.password.length > 0) {
        this.errorPassword = 'Заполните пароль от сети Wi-Fi'
      } else {
        this.errorPassword = ''
      }
      this.disableButton = false
    },
  },
  async mounted() {
    this.TOGGLE_PRELOADER(true)
    await this.FETCH_INFO_WIFI(this.GET_AUTHENTIFICATED)
    const res = await this.FETCH_INFO_WIFI(this.GET_AUTHENTIFICATED)
    this.network = res.network
    this.password = res.password ?? ''
    this.visibleWiFi = res.active
    setTimeout(() => {
      this.disableButton = true
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('informationWiFi', [
      'FETCH_INFO_WIFI',
      'UPDATING_WIFI_INFORMATION',
    ]),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),

    async toggleVisibleWiFi() {
      if (!this.visibleWiFi && this.network.length <= 0) {
        this.visibleWiFi = !this.visibleWiFi
      } else if (this.network.length > 0) {
        this.visibleWiFi = !this.visibleWiFi
        this.TOGGLE_PRELOADER(true)
        await this.UPDATING_WIFI_INFORMATION({
          token: this.GET_AUTHENTIFICATED,
          network: this.network,
          password: this.password,
          active: this.visibleWiFi,
        })
        setTimeout(() => {
          this.TOGGLE_PRELOADER(false)
        }, 500)
      }
    },

    async onInformationWiFi() {
      if (!this.network.length && this.password.length < 8) {
        this.errorNetwork = 'Заполните наименование сети Wi-Fi'
        this.errorPassword = 'Заполните пароль от сети Wi-Fi'
        this.disableButton = true
      } else if (!this.network.length) {
        this.errorNetwork = 'Заполните наименование сети Wi-Fi'
        this.disableButton = true
      } else if (this.password.length && this.password.length < 8) {
        this.errorPassword = 'Заполните пароль от сети Wi-Fi'
        this.disableButton = true
      } else {
        this.TOGGLE_PRELOADER(true)
        await this.UPDATING_WIFI_INFORMATION({
          token: this.GET_AUTHENTIFICATED,
          network: this.network,
          password: this.password,
          active: this.visibleWiFi,
        })
        setTimeout(() => {
          this.TOGGLE_PRELOADER(false)
        }, 500)
        this.disableButton = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.vBlockWiFiInformation {
  &__container {
    position: relative;
  }
  &__header {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #010d35;
    margin-bottom: 24px;
  }
  &__info {
    max-width: 257px;
    position: absolute;
    top: 0;
    right: -54%;
    @media (max-width: 1000px) {
      position: relative;
      left: 0;
      right: auto;
      max-width: 100%;
      margin-bottom: 16px;
    }
    &-title {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #010d35;
      margin-bottom: 12px;
    }
    &-text {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #4e5672;
    }
  }
}
</style>
