<template>
  <section
    class="vSectionCustomization section-profile"
    :class="{ reviewInfo__wrapper: selectionOptions === 'review' }"
  >
    <div class="vSectionCustomization__content">
      <div class="vSectionCustomization__switching">
        <button
          class="vSectionCustomization__switching-tabs"
          :class="{ tabs__active: selectionOptions === 'callingWaiter' }"
          @click="switchType('callingWaiter')"
        >
          Вызов официанта
        </button>
        <button
          class="vSectionCustomization__switching-tabs"
          :class="{ tabs__active: selectionOptions === 'informationWiFi' }"
          @click="switchType('informationWiFi')"
        >
          Информация о Wi-Fi
        </button>
        <button
          class="vSectionCustomization__switching-tabs"
          :class="{ tabs__active: selectionOptions === 'review' }"
          @click="switchType('review')"
        >
          Отзывы на внешних сервисах
        </button>
      </div>
      <div class="wide-line" />

      <VBlockCallingWaiter v-if="selectionOptions === 'callingWaiter'" />
      <VBlockWiFiInformation v-if="selectionOptions === 'informationWiFi'" />
      <VBlockReview v-if="selectionOptions === 'review'" />
    </div>

    <div
      v-if="selectionOptions === 'review'"
      class="vSectionCustomization__reviewInfo"
    >
      <VBlockReviewInfo />
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import VBlockCallingWaiter from '@/components/v-block-calling-waiter'
import VBlockWiFiInformation from '@/components/v-block-wi-fi-information'
import VBlockReview from '@/components/v-block-review'
import VBlockReviewInfo from '@/components/v-block-review-info'
export default {
  name: 'VSectionCustomization',
  components: {
    VBlockWiFiInformation,
    VBlockCallingWaiter,
    VBlockReview,
    VBlockReviewInfo,
  },
  data() {
    return {
      selectionOptions: 'callingWaiter',
    }
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    switchType(type) {
      this.selectionOptions = type
      this.TOGGLE_PRELOADER(true)
      setTimeout(() => {
        this.TOGGLE_PRELOADER(false)
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
.vSectionCustomization {
  .vLimitedInput__field {
    padding: 18px 42px 36px 12px;
  }

  &__content {
    background-color: #fff;
    border-radius: 11px;
    border: 1px solid #e6e7eb;
    padding: 24px 24px 8px 24px;
    max-width: 612px;
    height: max-content;
    h3 {
      margin-bottom: 24px;
    }
  }

  &__switching {
    width: 100%;
    background: #f7f8fa;
    border-radius: 14px;
    height: 48px;
    padding: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @media (max-width: 640px) {
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    &-tabs {
      color: #80869a;
      border-radius: 11px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      border: none;
      background: #f7f8fa;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex: auto;
      height: 100%;
      @media (max-width: 640px) {
        white-space: nowrap;
      }
    }
  }

  &__reviewInfo {
    max-width: 325px;
    margin-top: 110px;
    margin-left: 56px;
    @media (max-width: 1099px) {
      display: none;
    }
  }

  .tabs__active {
    background: #fff;
    color: #010d35;
  }
  .wide-line {
    width: 100%;
    border-top: 4px solid #f7f8fa;
    margin-bottom: 16px;
  }
}

.reviewInfo__wrapper {
  display: flex;
  @media (max-width: 1099px) {
    display: block;
  }
}
</style>
