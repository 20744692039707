<template>
  <div class="vBlockReviewInfo">
    <div class="vBlockReviewInfo__title">
      Отзывы на внешних сервисах
    </div>
    <div class="vBlockReviewInfo__description">
      На&nbsp;этой странице вы&nbsp;можете добавить возможность гостю оставить
      отзыв. Если гость поставил оценку 5&nbsp;звезд, то&nbsp;ему будет
      отображаться окно с&nbsp;выбором внешних сервисов, на&nbsp;которых
      он&nbsp;может дополнительно поставить оценку. Для активации этой функции
      необходимо активировать чекбокс &laquo;Включить отзывы на&nbsp;этой
      площадке&raquo; под каждым полем и&nbsp;добавить ссылки на&nbsp;написание
      отзывов предложенных сервисах.
    </div>

    <div
      class="vBlockReviewInfo__toggle"
      :class="{ active: socialBlock.includes(1) }"
      @click="toggleSocialBlock(1)"
    >
      <div class="vBlockReviewInfo__toggle-title">
        Для настройки отзывов Яндекс необходимо
        <img
          src="@/assets/images/arrow_toggle.svg"
          class="vBlockReviewInfo__toggle-arrow"
        >
      </div>
      <ul class="vBlockReviewInfo__toggle-list">
        <li class="vBlockReviewInfo__toggle-item">
          Перейти на страницу вашего заведения в Яндекс
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Перейти в отзывы
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Скопировать ссылку в кнопке «Написать отзыв»
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Вставить ссылку в поле «Ссылка на написание отзыва Яндекс»
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Нажать на кнопку «Включить отзывы Яндекс»
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Нажать на кнопку «Сохранить»
        </li>
      </ul>
    </div>

    <div
      class="vBlockReviewInfo__toggle"
      :class="{ active: socialBlock.includes(2) }"
      @click="toggleSocialBlock(2)"
    >
      <div class="vBlockReviewInfo__toggle-title">
        Для настройки отзывов 2Gis необходимо
        <img
          src="@/assets/images/arrow_toggle.svg"
          class="vBlockReviewInfo__toggle-arrow"
          :class="{ activeArrow: socialBlock.includes(2) }"
        >
      </div>
      <ul class="vBlockReviewInfo__toggle-list">
        <li class="vBlockReviewInfo__toggle-item">
          Перейти в ваше заведение на сайте 2Gis
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Перейти в отзывы
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Нажать на кнопку «Написать отзыв»
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Скопировать ссылку
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Вставить ссылку в поле «Ссылка на написание отзыва 2Gis»
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Нажать на кнопку «Включить отзывы в 2GIS»
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Нажать на «Сохранить»
        </li>
      </ul>
    </div>

    <div
      class="vBlockReviewInfo__toggle"
      :class="{ active: socialBlock.includes(3) }"
      @click="toggleSocialBlock(3)"
    >
      <div class="vBlockReviewInfo__toggle-title">
        Для настройки отзывов Google необходимо
        <img
          src="@/assets/images/arrow_toggle.svg"
          class="vBlockReviewInfo__toggle-arrow"
          :class="{ activeArrow: socialBlock.includes(3) }"
        >
      </div>
      <ul class="vBlockReviewInfo__toggle-list">
        <li class="vBlockReviewInfo__toggle-item">
          Написать в поисковике Google название вашего заведения
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Нажать на кнопку “Написать отзыв” в правой части страницы
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Скопировать текущую ссылку страницы
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Вставить ссылку в поле “Ссылка на написание отзыва Google”
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Нажать на кнопку “Включить отзывы Google”
        </li>
        <li class="vBlockReviewInfo__toggle-item">
          Нажать на кнопку "Сохранить"
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VBlockReviewInfo',

  data() {
    return {
      socialBlock: [],
    }
  },

  methods: {
    toggleSocialBlock(val) {
      const flag = this.socialBlock.includes(val)
      if (flag) {
        const test = this.socialBlock.filter((i) => i !== val)
        this.socialBlock = test
      } else {
        this.socialBlock.push(val)
      }

      console.log('socialBlock', this.socialBlock)
    },
  },
}
</script>

<style lang="scss" scoped>
.vBlockReviewInfo {
  // width: max-content;
  @media (max-width: 1099px) {
    width: auto;
  }
  &__title {
    color: #010d35;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 12px;
  }
  &__description {
    color: #4e5672;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 15px;
    max-width: 253px;
    // @media (max-width: 1099px) {
    //   max-width: max-content;
    // }
  }
  &__toggle {
    margin-bottom: 15px;
    cursor: pointer;
  }

  &__toggle-list {
    max-height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #4e5672;
    transition: all 0.5s;
    counter-reset: number;
  }

  &__toggle-item {
    position: relative;
    padding-left: 15px;
  }

  &__toggle-item::before {
    counter-increment: number;
    content: counter(number) '.';
    position: absolute;
    left: 0;
  }

  &__toggle-title {
    display: flex;
    align-items: center;
    padding-right: 24px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #4e5672;
  }

  &__toggle-arrow {
    transition: all 0.5s;
  }
}

.active .vBlockReviewInfo__toggle-arrow {
  transform: rotate(180deg);
  transition: all 0.5s;
}

.active .vBlockReviewInfo__toggle-list {
  max-height: 200px;
}
</style>
