<template>
  <div class="vBlockCallingWaiter">
    <div class="vBlockCallingWaiter__recipient">
      <h3>Кому отправлять сообщения гостя?</h3>
      <div class="vBlockCallingWaiter__radio">
        <div class="vBlockCallingWaiter__radio-cell">
          <label class="radio">
            <input
              v-model="recipient"
              type="radio"
              class="radio__input"
              value="WAITER_AND_MANAGER"
            >
            <div class="radio__label">Официанту и менеджеру</div>
          </label>
        </div>
        <div class="vBlockCallingWaiter__radio-cell">
          <label class="radio">
            <input
              v-model="recipient"
              type="radio"
              class="radio__input"
              value="MANAGER"
            >
            <div class="radio__label">Только менеджеру</div>
          </label>
        </div>
      </div>
      <div class="line" />
      <div class="vBlockCallingWaiter__recipient-info">
        <h4 class="vBlockCallingWaiter__recipient-title">
          Выбор адресата
        </h4>
        <p class="vBlockCallingWaiter__recipient-content">
          Выбранному адресату будут приходить сообщения гостя
        </p>
        <vIconWaiterManager v-if="recipient === 'WAITER_AND_MANAGER'" />
        <vIconManager v-if="recipient !== 'WAITER_AND_MANAGER'" />
      </div>
    </div>
    <!--    <div class="vBlockCallingWaiter__message">-->
    <!--      <h3>Шаблон текста сообщения</h3>-->
    <!--      <VLimitedInput-->
    <!--        v-model="response_template"-->
    <!--        placeholder="Текст отправки сообщения"-->
    <!--        :value="response_template"-->
    <!--      />-->
    <!--      <div class="line" />-->
    <!--      <div class="vBlockCallingWaiter__message-info">-->
    <!--        <h4 class="vBlockCallingWaiter__message-title">-->
    <!--          Текст сообщения об отправке-->
    <!--        </h4>-->
    <!--        <p class="vBlockCallingWaiter__message-content">-->
    <!--          В это поле вы можете вписать текст, который будет отображаться после-->
    <!--          отправки сообщения гостем-->
    <!--        </p>-->
    <!--        <span>Пример отображения:</span>-->
    <!--        <div class="vBlockCallingWaiter__message-example">-->
    <!--          <VIconSuccessfulSending />-->
    <!--          <p>{{ displayExample }}</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <vButton
      type="button"
      :disabled="disableButton"
      @click="onDisplayExample"
    >
      Сохранить
    </vButton>
    <!--    </div>-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vButton from '@/components/v-button'
// import VLimitedInput from '@/components/v-limited-input'
import vIconWaiterManager from '@/assets/images/icons/v-icon-waiter-manager'
import vIconManager from '@/assets/images/icons/v-icon-manager'
// import VIconSuccessfulSending from '@/assets/images/icons/v-icon-successful-sending'
export default {
  name: 'VBlockCallingWaiter',
  components: {
    vButton,
    // VLimitedInput,
    vIconWaiterManager,
    vIconManager,
    // VIconSuccessfulSending,
  },
  data() {
    return {
      modal: null,
      // response_template: 'Сообщение успешно отправлено',
      recipient: 'WAITER_AND_MANAGER',
      // displayExample: 'Сообщение успешно отправлено',
      disableButton: true,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('customization', ['SERVICES']),
  },
  watch: {
    // response_template() {
    //   this.disableButton = false
    // },

    recipient() {
      this.disableButton = false
    },
  },
  async mounted() {
    await this.GET_CUSTOMIZATION_SERVIVES({ token: this.GET_AUTHENTIFICATED })
    const res = await this.SHOP_INFORMATION({ token: this.GET_AUTHENTIFICATED })
    // this.response_template = res.response_template
    // this.displayExample = res.response_template
    this.recipient = res.notification_recipient
    console.log('res', res.notification_recipient)
    setTimeout(() => {
      this.disableButton = true
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('customization', [
      'CUSTOMIZATION',
      'GET_CUSTOMIZATION_SERVIVES',
    ]),
    ...mapActions('send', ['CHANGE_RECIPIEND', 'SHOP_INFORMATION']),
    async onDisplayExample() {
      // this.displayExample = this.response_template
      await this.CHANGE_RECIPIEND({
        token: this.GET_AUTHENTIFICATED,
        recipient: this.recipient,
        // response_template: this.response_template,
      })
      this.disableButton = true
    },
  },
}
</script>

<style lang="scss" scoped>
.vBlockCallingWaiter {
  &__recipient {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #010d35;
    position: relative;
    &-title {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 12px;
    }
    &-content {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 12px;
    }
    &-info {
      max-width: 257px;
      position: absolute;
      top: 0;
      right: -54%;
    }
  }
  &__radio {
    &-cell {
      margin-bottom: 20px;
    }
  }
  &__message {
    margin-top: 16px;
    position: relative;
    &-title {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 12px;
    }
    &-content {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
    }
    &-example {
      width: 267px;
      margin-top: 12px;
      padding: 10px 0 12px 0;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        max-width: 170px;
        text-align: center;
      }
    }
    &-info {
      max-width: 257px;
      position: absolute;
      top: 0;
      right: -54%;
      span {
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: #6764ff;
      }
    }
  }
  .radio {
    position: relative;
    margin-bottom: 20px;
    &__input {
      position: absolute;
      appearance: none;
      -webkit-appearance: none;
    }
    &__label {
      padding-left: 35px;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #010d35;
      cursor: pointer;
      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border: 3px solid #cccfd7;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transition: border 0.1s linear;
      }
      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background-color: #6764ff;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        left: 6px;
        z-index: 2;
        opacity: 0;
        transition: opacity 0.1s linear;
      }
    }
  }
  .radio__input:checked + .radio__label:before {
    background-color: #fff;
    border-color: blue;
  }
  .radio__input:checked + .radio__label:after {
    opacity: 1;
  }
}
@media screen and (max-width: 769px) {
  .vBlockCallingWaiter {
    &__recipient-info {
      position: relative;
      top: auto;
      right: auto;
      margin-bottom: 16px;
      max-width: 100%;
    }
    &__message {
      &-info {
        position: relative;
        top: auto;
        right: auto;
        margin-bottom: 16px;
        max-width: 100%;
      }
      &-example {
        width: 100%;
        border: 1px solid #cccfd7;
        p {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
