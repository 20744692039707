<template>
  <div class="vBlockReview">
    <div class="vBlockReview__info">
      <VBlockReviewInfo />
    </div>
    <form class="vBlockReview__form">
      <div class="vBlockReview__item">
        <VInput
          id="yandex"
          v-model="yandex.link"
          :value="yandex.link"
          placeholder="Ссылка на написание отзыва Яндекс"
          :margin-bottom="10"
          :mobile-screen="screenWidth > 0 && screenWidth < 475"
          :error="yandex.error || yandex.emptyFieldError || yandex.linkError"
          @input="yandex.linkError = ''"
        >
          <img
            src="@/assets/images/iconAdmin/yandex.svg"
            alt="yandex"
          >
        </VInput>

        <div class="vBlockReview__checkbox">
          <VCheckbox
            id="checkYandex"
            :value="yandex.checkbox"
            :size="20"
            fill
            label="Включить отзывы Яндекс"
            position-label="end"
            :checkbox-margin-right="18"
            @click="changeCheckbox(yandex)"
            @clickHandler="changeCheckbox(yandex)"
          />
        </div>
      </div>

      <div class="vBlockReview__item">
        <VInput
          id="twoGis"
          v-model="twoGis.link"
          :value="twoGis.link"
          placeholder="Ссылка на написание отзыва 2Gis"
          :margin-bottom="10"
          :mobile-screen="screenWidth > 0 && screenWidth < 475"
          :error="twoGis.error || twoGis.emptyFieldError || twoGis.linkError"
          @input="twoGis.linkError = ''"
        >
          <img
            src="@/assets/images/iconAdmin/twoGis.svg"
            alt="yandex"
            width="24"
            height="24"
          >
        </VInput>

        <div class="vBlockReview__checkbox">
          <VCheckbox
            id="checkTwoGis"
            :value="twoGis.checkbox"
            :size="20"
            fill
            label="Включить отзывы 2GIS"
            position-label="end"
            :checkbox-margin-right="18"
            @click="changeCheckbox(twoGis)"
            @clickHandler="changeCheckbox(twoGis)"
          />
        </div>
      </div>

      <div class="vBlockReview__item">
        <VInput
          id="google"
          v-model="google.link"
          :value="google.link"
          placeholder="Ссылка на написание отзыва Google"
          :margin-bottom="10"
          :mobile-screen="screenWidth > 0 && screenWidth < 475"
          :error="google.error || google.emptyFieldError || google.linkError"
          @input="google.linkError = ''"
        >
          <img
            src="@/assets/images/iconAdmin/google.svg"
            alt="yandex"
          >
        </VInput>

        <div class="vBlockReview__checkbox">
          <VCheckbox
            id="checkGoogle"
            :value="google.checkbox"
            :size="20"
            fill
            label="Включить отзывы Google"
            position-label="end"
            :checkbox-margin-right="18"
            @click="changeCheckbox(google)"
            @clickHandler="changeCheckbox(google)"
          />
        </div>
      </div>
    </form>

    <VButton @handleClick="checkForm">
      Сохранить
    </VButton>

    <VPreloader
      v-show="loading"
      :transparent="true"
      :fixed="false"
    />
  </div>
</template>

<script>
import VBlockReviewInfo from '@/components/v-block-review-info'
import VInput from '@/components/v-input'
import VCheckbox from '@/components/v-checkbox'
import VButton from '@/components/v-button'
import VPreloader from '@/components/v-light-preloader'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'VBlockReview',
  components: {
    VBlockReviewInfo,
    VInput,
    VCheckbox,
    VButton,
    VPreloader,
  },

  data() {
    return {
      yandex: {
        link: '',
        checkbox: false,
        error: '',
        emptyFieldError: '',
        linkError: '',
      },
      twoGis: {
        link: '',
        checkbox: false,
        error: '',
        emptyFieldError: '',
        linkError: '',
      },
      google: {
        link: '',
        checkbox: false,
        error: '',
        emptyFieldError: '',
        linkError: '',
      },
      screenWidth: 0,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },

  watch: {
    yandex: {
      deep: true,
      handler() {
        if (this.yandex.link.length) {
          this.yandex.emptyFieldError = ''
          if (!this.yandex.link.includes('yandex.')) {
            this.yandex.error = 'Введите корректную ссылку'
          } else {
            this.yandex.error = ''
          }
        } else if (!this.yandex.link.length && !this.yandex.checkbox) {
          this.yandex.emptyFieldError = ''
          this.yandex.error = ''
        } else {
          this.yandex.error = ''
        }
      },
    },

    twoGis: {
      deep: true,
      handler() {
        if (this.twoGis.link.length) {
          this.twoGis.emptyFieldError = ''
          if (!this.twoGis.link.includes('2gis.')) {
            this.twoGis.error = 'Введите корректную ссылку'
          } else {
            this.twoGis.error = ''
          }
        } else if (!this.twoGis.link.length && !this.twoGis.checkbox) {
          this.twoGis.emptyFieldError = ''
          this.twoGis.error = ''
        } else {
          this.twoGis.error = ''
        }
      },
    },

    google: {
      deep: true,
      handler() {
        if (this.google.link.length) {
          this.google.emptyFieldError = ''
          if (!this.google.link.includes('google.')) {
            this.google.error = 'Введите корректную ссылку'
          } else {
            this.google.error = ''
          }
        } else if (!this.google.link.length && !this.google.checkbox) {
          this.google.emptyFieldError = ''
          this.google.error = ''
        } else {
          this.google.error = ''
        }
      },
    },
  },

  async mounted() {
    this.screenWidth = window.innerWidth
    const res = await this.FETCH_EXTERNAL_REVIEWS(this.GET_AUTHENTIFICATED)
    if (res.length) {
      res.forEach((el) => {
        if (el.platform === 'yandex') {
          this.yandex.link = el.url ?? ''
          this.yandex.checkbox = el.active === 1
        }
        if (el.platform === '2gis') {
          this.twoGis.link = el.url ?? ''
          this.twoGis.checkbox = el.active === 1
        }
        if (el.platform === 'google') {
          this.google.link = el.url ?? ''
          this.google.checkbox = el.active === 1
        }
      })
    }
  },

  methods: {
    ...mapActions('externalReviews', [
      'FETCH_EXTERNAL_REVIEWS',
      'SAVE_EXTERNAL_REVIEWS',
    ]),

    changeCheckbox(val) {
      val.checkbox = !val.checkbox
    },

    checkForm() {
      let err = false
      if (
        this.yandex.error.length ||
        this.twoGis.error.length ||
        this.google.error.length ||
        this.yandex.emptyFieldError.length ||
        this.twoGis.emptyFieldError.length ||
        this.google.emptyFieldError.length ||
        this.yandex.linkError ||
        this.twoGis.linkError ||
        this.google.linkError
      ) {
        err = true
      }
      if (this.yandex.checkbox && !this.yandex.link.length) {
        err = true
        this.yandex.emptyFieldError = 'Для сохранения необходимо заполнить поле'
      }
      if (this.twoGis.checkbox && !this.twoGis.link.length) {
        this.twoGis.emptyFieldError = 'Для сохранения необходимо заполнить поле'
        err = true
      }
      if (this.google.checkbox && !this.google.link.length) {
        err = true
        this.google.emptyFieldError = 'Для сохранения необходимо заполнить поле'
      }
      if (!err) {
        this.saveExternalReviews()
      }
    },

    async saveExternalReviews() {
      this.loading = true
      const data = {
        token: this.GET_AUTHENTIFICATED,
        query: {
          platforms: [
            {
              platform: 'yandex',
              url: this.yandex.link,
              active: this.yandex.checkbox ? 1 : 0,
            },
            {
              platform: '2gis',
              url: this.twoGis.link,
              active: this.twoGis.checkbox ? 1 : 0,
            },
            {
              platform: 'google',
              url: this.google.link,
              active: this.google.checkbox ? 1 : 0,
            },
          ],
        },
      }
      const response = await this.SAVE_EXTERNAL_REVIEWS(data)

      if (response?.errors) {
        for (let error in response.errors) {
          const arr = error.split('.')
          let err = response.errors[error]
          if (arr[1] === '0') {
            this.yandex.linkError = err[0]
          }

          if (arr[1] === '1') {
            this.twoGis.linkError = err[0]
          }

          if (arr[1] === '2') {
            this.google.linkError = err[0]
          }
        }
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.vBlockReview {
  position: relative;
  &__info {
    display: none;
    @media (max-width: 1099px) {
      display: block;
    }
  }

  &__form {
    margin-bottom: 34px;
  }

  &__item:not(:last-child) {
    margin-bottom: 18px;
  }

  &__checkbox {
    display: inline-block;
  }
}
</style>
