<template>
  <div class="vAdminDisplayingWiFi">
    <div class="vAdminDisplayingWiFi__text">
      Отображать информацию о Wi-Fi
    </div>
    <label class="vAdminDisplayingWiFi__switch">
      <input
        type="checkbox"
        :checked="visibleWiFi"
        @click="toggleVisibleWiFi"
      >
      <span class="vAdminDisplayingWiFi__slider" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'VAdminDisplayingWiFi',
  props: {
    visibleWiFi: {
      type: Number,
      required: true,
      default: null,
    },
  },
  emits: ['toggleVisibleWiFi'],
  methods: {
    toggleVisibleWiFi() {
      this.$emit('toggleVisibleWiFi')
    },
  },
}
</script>

<style lang="scss">
.vAdminDisplayingWiFi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e6e7eb;
  border-radius: 11px;
  padding: 0 16px;
  margin-bottom: 32px;
  @media (max-width: 564px) {
    height: 68px;
  }
  &__text {
    color: #010d35;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    @media (max-width: 564px) {
      max-width: 175px;
    }
  }
  &__switch {
    position: relative;
    display: inline-block;
    min-width: 50px;
    height: 24px;
    @media (max-width: 564px) {
      max-width: 42px;
    }
    & input {
      display: none;
      &:checked + .vAdminDisplayingWiFi__slider {
        background-color: #6764ff;
      }
      &:disabled + .vAdminDisplayingWiFi__slider {
        background-color: #e6e7eb;
      }
      &:checked + .vAdminDisplayingWiFi__slider::before {
        transform: translateX(26px);
      }
    }
  }
  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6e7eb;
    transition: 0.4s;
    border-radius: 50px;
    &::before {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      left: 2px;
      bottom: 2px;
      background-color: #fff;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}
</style>
